import React from 'react';
import {
  Box, Stack, Button, AppBar, Divider, Container, Toolbar, Menu,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import chevronIcon from '@iconify/icons-carbon/chevron-down';
import Logo from '../../assets/images/Logo.png';
import HexaLogoAnimated from '../../assets/images/HexaLogoAnimated.gif';

import { useResponsive } from '../../hooks';
import NavMobile from './NavMobile';
import Iconify from '../Iconify';

export default function HeaderNavBar() {
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'md');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNavigateAndClose = (link) => {
    navigate(link);
    setAnchorEl(null);
  };
  return (
    <AppBar
      color="primary"
      sx={{
        color: '#FFFFFF',
        backgroundColor: 'rgba(238, 241, 239, 0.9)',
        backdropFilter: 'blur(6px)',
      }}
    >
      <Toolbar sx={{ color: '#FFFFFF', backgroundColor: 'transparent', padding: '14px' }}>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '0',
            paddingRight: '6px',
            '@media (min-width: 600px)': {
              paddingLeft: '16px',
              paddingRight: '24px',
            },
          }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <Button
              variant="text"
              onClick={() => {
                navigate('/');
              }}
              sx={{ paddingLeft: '8' }}
            >
              <img src={HexaLogoAnimated} style={{ width: '24px', paddingTop: '2px', marginRight: '3px' }} alt="Hexagon logo" />
              <img src={Logo} style={{ width: '100px', paddingTop: '5px', marginLeft: '5px' }} alt="Enquanta logo" />
            </Button>
          </Box>
          {isDesktop
            && (
              <Stack
                direction="row"
                spacing={0}
              >
                <Button
                  variant="text"
                  href=""
                  target="_blank"
                  rel="noopener"
                  onClick={handleClick}
                  sx={{
                    fontSize: '1rem',
                    '.MuiButton-endIcon': {
                      marginLeft: '0px',
                    },
                  }}
                  style={{ paddingTop: '9px' }}
                  endIcon={<Iconify icon={chevronIcon} sx={{ width: 18, height: 18 }} />}
                >
                  Quanta
                  <span className="landingHighlightedWords">Safe</span>
                </Button>
                <Menu
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleNavigateAndClose}
                >
                  <MenuItem onClick={() => { handleNavigateAndClose('/whatIsQuantaSafe'); }}>
                    What is QuantaSafe?
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />

                  <MenuItem onClick={() => { handleNavigateAndClose('/secureVault'); }}>
                    Secure Vault
                  </MenuItem>
                  <MenuItem onClick={() => { handleNavigateAndClose('/secureStorage'); }}>
                    Secure Storage
                  </MenuItem>
                  <MenuItem onClick={() => { handleNavigateAndClose('/secureTransfer'); }}>
                    Secure Transfer
                  </MenuItem>
                  <MenuItem onClick={() => { handleNavigateAndClose('/secureComms'); }}>
                    Secure Comms
                  </MenuItem>
                  <MenuItem onClick={() => { handleNavigateAndClose('/secureLite'); }}>
                    Secure Lite
                  </MenuItem>
                  <MenuItem onClick={() => { handleNavigateAndClose('/secureDatabase'); }}>
                    Secure Database
                  </MenuItem>
                  <MenuItem onClick={() => { handleNavigateAndClose('/secureAgent'); }}>
                    Secure Agent
                  </MenuItem>
                </Menu>
                <Button
                  variant="text"
                  href=""
                  target="_blank"
                  rel="noopener"
                  onClick={() => {
                    navigate('blog');
                  }}
                  sx={{
                    fontSize: '1rem',
                    justifyContent: 'flex-start',
                    // the global minWidth is 64px,
                    // which causes this button to be unevenly highlighted
                    minWidth: '30px',
                  }}
                  style={{ paddingTop: '9px' }}
                >
                  Blog
                </Button>
              </Stack>
            )}

          <Box sx={{ flexGrow: 1 }} />

          <Stack spacing={2} direction="row" alignItems="center">

            <Divider orientation="vertical" sx={{ height: 24 }} />
            {isDesktop
              && (
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    href=""
                    target="_blank"
                    rel="noopener"
                    sx={{
                      backgroundColor: '#054A91',
                      fontSize: '1rem',
                      '&:hover': {
                        backgroundColor: '#2474B5 !important',
                        // color: '#637381',
                      },
                    }}
                    onClick={() => {
                      navigate('contactUs');
                    }}
                  >
                    Contact Us
                  </Button>
                </Stack>
              )}

            {!isDesktop && (
              <NavMobile />
            )}

          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
