/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './ProblemsEnquanta.css';
import {
  Box,
  Typography,
  Grid,
  Container,
  Link,
} from '@mui/material';
import CyberSecurityImage from '../../assets/images/CyberSecurity.png';
import QuantumThreatImage from '../../assets/images/QuantumThreat.png';
import CPIimage from '../../assets/images/CPI.png';
import FolderLock from '../../assets/images/folderLock.png';
import { pxToRem, responsiveFontSizes } from '../../utils/getFontValue';
import getWindowSize from '../../utils/getScreenSize';

const regularTextFontSizes = {
  xs: 16, sm: 18, md: 18, lg: 18,
};

function CyberSecurity1({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      Systems are hacked every 11 seconds, revealing the vulnerability of digital infrastructure.
      80% of IT leaders lack confidence in their company&apos;s cybersecurity readiness.
    </Typography>
  );
}

function CyberSecurity2({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      Data breaches have far-reaching consequences beyond the initial incident.
      Non-compliance costs three times more than compliance.
      Insurance companies have raised E&O/cyber coverage premiums by 10 times in the past two years.
    </Typography>
  );
}

function CyberSecurity3({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      These trends emphasize the need for enhanced cybersecurity
      measures and risk management strategies to address escalating cyber threats.
    </Typography>
  );
}

function CPI1({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      It is imperative to strictly adhere to DoD Directives
      {' '}
      <Link
        target="_blank"
        href="https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodd/520047E.PDF?ver=dn6WWtr6ADd_PmzB4dM9hw=="
        underline="always"
        sx={{
          color: '#85CAEB',
          textDecoration: 'underline',

        }}
      >
        5200.47E and 5200.39
      </Link>
      ,
      as they give top priority to preventing adversaries from obtaining unauthorized
      access to your Critical Program Information(CPI). Their primary focus is to counteract
      any attempts of reverse engineering your technology and subsequently
      exploiting it against your interests.
    </Typography>
  );
}

function CPI2({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      The implementation of outdated Anti-Tamper (AT) methods presents
      considerable challenges and inadequacies in ensuring the effective
      protection of valuable assets. Merely relying on encryption alone
      proves insufficient in mitigating potential risks.
    </Typography>
  );
}

function CPI3({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      It is worth noting that adversaries and nation states actively
      concentrate their efforts on the theft of CPI, elevating it to the paramount
      objective within their malicious activities.
    </Typography>
  );
}

function QuantumThreats1({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      In the near future, the rapid advancement of Cryptographically Relevant Quantum Computers
      (CRQC) with AI is poised to render conventional encryption
      techniques ineffective and obsolete. And just like when Alan Turing cracked
      the Enigma machine, your adversaries will never tell you that they can hack
      these conventional encryption techniques.
    </Typography>
  );
}

function QuantumThreats2({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      As Cryptographically Relevant Quantum Computers (CRQC) continue to advance,
      a troubling pattern is emerging where hackers are actively engaging in the
      theft of valuable information, even conventionally encrypted CPI.
      These nefarious individuals either sell
      the stolen data or store it for decryption at a later time.
    </Typography>
  );
}

function QuantumThreats3({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      This alarming phenomenon highlights the urgency to adapt and strengthen our security
      measures in order to effectively counteract the evolving landscape of cyber threats.
    </Typography>
  );
}

const problemsInfo = [
  {
    title: // eslint-disable-next-line react/jsx-indent
      <>
        Cyber Security
        <br />
        {' '}
        is Failing.
      </>,
    imageSrc: CyberSecurityImage,
    bullets: [
      `Systems are hacked every 11 seconds, revealing the vulnerability of digital infrastructure.
      80% of IT leaders lack confidence in their company's cybersecurity readiness.`,
      `Data breaches have far-reaching consequences beyond the initial incident. Non-compliance costs three times more than compliance.
      Insurance companies have raised E&O/cyber coverage premiums by 10 times in the past two years.`,
      `These trends emphasize the need for enhanced cybersecurity
      measures and risk management strategies to address escalating cyber threats.`,
    ],
  },
  {
    title: 'How do you protect your Critical Program Information (CPI)?',
    imageSrc: FolderLock,
    bullets: [
      `Adhering to DoD Directives becomes of utmost importance as they prioritize the prevention of adversaries
      gaining unauthorized access to your Critical Program Information (CPI) with the intention of reverse engineering
      your technology and utilizing it against you.`,
      `The implementation of outdated Anti-Tamper (AT) methods presents considerable challenges and inadequacies
      in ensuring the effective protection of valuable assets.
      Merely relying on encryption alone proves insufficient in mitigating potential risks.`,
      `It is worth noting that adversaries and nation states actively concentrate their efforts on the theft of CPI,
      elevating it to the paramount objective within their malicious activities.`,
    ],
  },
  {
    title:
      // eslint-disable-next-line react/jsx-indent
      <>
        Quantum Threats
        <br />
        {' '}
        Are Real.
      </>,
    imageSrc: QuantumThreatImage,
    bullets: [
      `In the near future, the rapid advancement of Cryptographically Relevant Quantum Computers (CRQC)
      with AI is poised to render conventional encryption techniques entirely ineffective and obsolete.`,
      `As Cryptographically Relevant Quantum Computers (CRQC) continue to advance,
      a troubling pattern is emerging, wherein hackers are actively engaging in the theft of valuable information.
      These nefarious individuals either sell the stolen data or store it for decryption at a later time.`,
      `This alarming phenomenon highlights the urgency to adapt and strengthen our security
      measures in order to effectively counteract the evolving landscape of cyber threats.`,
    ],
  },
];

export default function ProblemsEnquanta() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  let CyberSecurityImageHeight = '230px';
  let CpiImageHeight = '180px';
  let QuantumThreatImageHeight = '230px';
  if (windowSize.innerWidth >= 1200) {
    CyberSecurityImageHeight = '360px';
    CpiImageHeight = '320px';
    QuantumThreatImageHeight = '320px';
  } else if (windowSize.innerWidth >= 900) {
    CyberSecurityImageHeight = '300px';
    CpiImageHeight = '250px';
    QuantumThreatImageHeight = '285px';
  } else if (windowSize.innerWidth >= 600) {
    CyberSecurityImageHeight = '300px';
    CpiImageHeight = '250px';
    QuantumThreatImageHeight = '300px';
  }

  const shouldBreakTheHeader = windowSize.innerWidth < 650;
  const showBoxes = windowSize.innerWidth >= 900;
  return (
    <Box sx={{
      height: 'auto',
      minHeight: '100vh',
      backgroundColor: '#422A76',
      color: '#EEF1EF',
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    }}
    >
      <Container sx={{
        paddingTop: 6,
        paddingBottom: 4,
      }}
      >
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            mx: 0,
            textAlign: 'center',
            fontSize: pxToRem(34),
            fontFamily: 'branding',
            fontWeight: 'bold',
            ...responsiveFontSizes({
              xs: 32, sm: 36, md: 40, lg: 48,
            }),
            lineHeight: { xs: 1.2, md: 1.75 },
          }}
        >
          Problems EnQuanta
          {
            !(shouldBreakTheHeader) && ' '
          }
          {shouldBreakTheHeader
            && <br />}
          Solves Today

        </Typography>
        <Box sx={{
          height: 'auto',
          minHeight: { md: '100vh' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        >
          <Container sx={{
            pt: { xs: 3, md: 3, lg: 9 },
            pb: { xs: 3, md: 3, lg: 9 },
            pl: { xs: 0, lg: 0 },
            pr: { xs: 0, lg: 0 },
          }}
          >
            <Grid
              container
              spacing={{
                xs: 0, sm: 0, md: 0, lg: 0,
              }}
              my={{
                xs: 3, sm: 3, md: 3, lg: 0,
              }}
              rowGap={{
                xs: 10, sm: 10, md: 7, lg: 5,
              }}
              // rowSpacing={{ lg: 10 }}
              sx={{ justifyContent: 'center' }}
            >
              {windowSize.innerWidth >= 900
                && (
                  <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        py: 1,
                        display: 'flex',
                      }}
                    >
                      <img
                        src={CyberSecurityImage}
                        alt="QuantaSafe"
                        style={{ height: CyberSecurityImageHeight }}
                      />
                    </Box>
                  </Grid>
                )}
              <Grid item md={6}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: '5px',
                    ...responsiveFontSizes({
                      xs: 22, sm: 28, md: 28, lg: 32,
                    }),
                    fontWeight: 'bold',
                  }}
                >
                  Cyber Security is Failing.
                </Typography>
                <CyberSecurity1 />
                {windowSize.innerWidth < 900 && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 2,
                      my: { xs: 0, md: 4, lg: 2 },
                    }}
                  >
                    <img
                      src={CyberSecurityImage}
                      alt="QuantaSafe"
                      style={{ height: CyberSecurityImageHeight }}
                    />
                  </Box>
                )}
                <CyberSecurity2 />
                <CyberSecurity3 />
              </Grid>
              <Grid item md={6}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: '5px',
                    ...responsiveFontSizes({
                      xs: 22, sm: 28, md: 28, lg: 32,
                    }),
                    fontWeight: 'bold',
                  }}
                >
                  How do you protect your Critical Program Information (CPI)?
                </Typography>
                <CPI1 />
                {windowSize.innerWidth < 900 && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 2,
                      my: { xs: 0, md: 4, lg: 2 },
                    }}
                  >
                    <img
                      src={CPIimage}
                      alt="QuantaSafe"
                      style={{ height: CpiImageHeight }}
                    />
                  </Box>
                )}
                <CPI2 />
                <CPI3 />
              </Grid>

              {windowSize.innerWidth >= 900
                && (
                  <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        py: 1,
                        display: 'flex',
                      }}
                    >
                      <img
                        src={CPIimage}
                        alt="QuantaSafe"
                        style={{ height: CpiImageHeight }}
                      />
                    </Box>
                  </Grid>
                )}

              {windowSize.innerWidth >= 900
                && (
                  <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        py: 1,
                        display: 'flex',
                      }}
                    >
                      <img
                        src={QuantumThreatImage}
                        alt="QuantaSafe"
                        style={{ height: QuantumThreatImageHeight }}
                      />
                    </Box>
                  </Grid>
                )}
              <Grid item md={6}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: '5px',
                    ...responsiveFontSizes({
                      xs: 22, sm: 28, md: 28, lg: 32,
                    }),
                    fontWeight: 'bold',
                  }}
                >
                  Quantum Threats Are Real.
                </Typography>
                <QuantumThreats1 />
                {windowSize.innerWidth < 900 && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 2,
                      my: { xs: 0, md: 4, lg: 2 },
                    }}
                  >
                    <img
                      src={QuantumThreatImage}
                      alt="QuantaSafe"
                      style={{ height: QuantumThreatImageHeight }}
                    />
                  </Box>
                )}
                <QuantumThreats2 />
                <QuantumThreats3 />
              </Grid>
            </Grid>
          </Container>
        </Box>

      </Container>
    </Box>
  );
}
