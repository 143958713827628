/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Container, Typography, Box, Grid,
} from '@mui/material';
import { responsiveFontSizes } from '../../utils/getFontValue';
import QuantumIntelligentData from '../../assets/images/arrow.png';
import './PostLandingText.css';
import getWindowSize from '../../utils/getScreenSize';
import useResponsive from '../../hooks/useResponsive';

const regularTextFontSizes = {
  xs: 16, sm: 18, md: 18, lg: 18,
};

function QuantaSafeParagraph({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      EnQuanta&apos;s groundbreaking QuantaSafe technology, powered by
      our patent-pending
      {' '}
      <span style={{ fontWeight: 'bold', color: '#7F5CAD' }}>Quantum Hybrid Ciphers</span>
      {' '}
      system, provides comprehensive cyber
      resilience for your digital assets, shielding them from breaches. With QuantaSafe,
      you can operate confidently, knowing that theft and unauthorized access
      are effectively neutralized, ensuring uninterrupted operations.
    </Typography>
  );
}

function IncryptedParagraph({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      Our term “Incryption” is somewhat analogous to “Encryption”, but encompasses
      our expanded cryptographic agile process that can include one or more standard
      encryption algorithms plus a random order of our non-standard cipher processes.
    </Typography>
  );
}

export default function PostLandingText() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const isDesktop = useResponsive('up', 'md');

  let whatIsQuantaSafeImageHeight = '220px';
  if (windowSize.innerWidth >= 1200) {
    whatIsQuantaSafeImageHeight = '200px';
  } else if (windowSize.innerWidth >= 900) {
    whatIsQuantaSafeImageHeight = '150px';
  } else if (windowSize.innerWidth >= 600) {
    whatIsQuantaSafeImageHeight = '300px';
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (
    <Box sx={{
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      height: 'auto',
      minHeight: { md: '70vh' },
      display: 'flex',
    }}
    >
      <Container sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', pt: { xs: 0, md: 3 }, pb: { xs: 6, md: 8 },
      }}
      >
        <Grid
          container
          spacing={{
            xs: 0, sm: 0, md: 0, lg: 3,
          }}
          my={{
            xs: 3, sm: 3, md: 0, lg: 0,
          }}
          rowGap={{
            xs: 3, sm: 5, md: 7, lg: 7,
          }}
          // rowSpacing={{ lg: 10 }}
          sx={{ justifyContent: 'center' }}
        >
          <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }} order={{ xs: 2, md: 1 }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                py: 1,
                display: 'flex',
              }}
            >
              <img
                src={QuantumIntelligentData}
                alt="QuantaSafe"
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item md={6} order={{ xs: 1, md: 2 }}>
            <Typography
              variant="h3"
              sx={{
                marginBottom: '5px',
                ...responsiveFontSizes({
                  xs: 22, sm: 28, md: 28, lg: 32,
                }),
                fontWeight: 'bold',
                color: '#422A76',
              }}
            >
              Quantum Incrypted Data.
            </Typography>
            <QuantaSafeParagraph />
            {isDesktop && <IncryptedParagraph />}
          </Grid>
          {!isDesktop && (
            <Grid item md={6} order={{ xs: 3 }}>
              <IncryptedParagraph />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
