/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  Link,
  Button,
  CardActions,
  Avatar,
} from '@mui/material';
import WAVES from 'vanta/dist/vanta.waves.min';
import locationIcon from '@iconify/icons-carbon/location';
import mobileIcon from '@iconify/icons-carbon/mobile';
import emailIcon from '@iconify/icons-carbon/email';
import { m } from 'framer-motion';
import Iconify from '../Iconify';
import TextIconLabel from '../TextIconLabel';
import getWindowSize from '../../utils/getScreenSize';

import WhatIsQuantaSafeCoverImage from '../../assets/images/CoverImages/QuantaSafeCoverImage.png';
import { responsiveFontSizes } from '../../utils/getFontValue';
import SecureAgentImage from '../../assets/images/SecureAgent.png';
import planeImage from '../../assets/images/plane.png';

// TODO: Check if this is needed anymore3
import '../EnquantaAdvantage/EnquantaAdvantage.css';

// TODO: clean up this doo doo

const regularTextFontSizes = {
  xs: 16, sm: 18, md: 18, lg: 18,
};

function Intro({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      QuantaSafe Secure Agent is an advanced system designed for executing commands on target
      devices remotely and securely. This technology offers a range of capabilities, such as
      remotely adjusting operational parameters of sensor nodes like video feeds, including
      changing their direction.
    </Typography>
  );
}

function BodyParagraph1({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      It also plays a crucial role in the secure distribution of software updates to multiple target nodes.
      A key feature of QuantaSafe Secure Agent is its software/firmware anti-tamper functionality,
      which ensures that executable software is installed only when necessary for device operation,
      enhancing security against unauthorized access. Additionally, this system is adept at remotely
      controlling various hardware, including vehicles. This remote control capability is safeguarded
      by securing the binary and resources essential for these operations, thereby maintaining safety
      and integrity even in scenarios where the target device might be stolen.
      This comprehensive approach to device management and security makes QuantaSafe Secure Agent a
      robust tool in the realm of remote device control and protection.
    </Typography>

  );
}

export default function SecureAgent() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(WAVES({
        el: myRef.current,
        color: 0x251742,
        shininess: 75.00,
        waveHeight: 16.00,
        waveSpeed: 1.00,
        zoom: 0.93,
      }));
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  let quantumIntelligenceDiagramHeight = '250px';
  if (windowSize.innerWidth >= 1200) {
    quantumIntelligenceDiagramHeight = '370px';
  } else if (windowSize.innerWidth >= 900) {
    quantumIntelligenceDiagramHeight = '320px';
  } else if (windowSize.innerWidth >= 600) {
    quantumIntelligenceDiagramHeight = '300px';
  }
  return (
    <>

      <Box
        ref={myRef}
        sx={{
          backgroundImage: `url(${WhatIsQuantaSafeCoverImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: '100% 100%',
          marginTop: '64px',

        }}
      >
        <Container sx={{
          width: '100vw',
          height: {
            xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        >
          <Grid container columnSpacing={3}>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <Typography
                variant="h1"
                sx={{
                  color: '#EEF1EF',
                  fontFamily: 'branding',
                  fontWeight: 'bold',
                  ...responsiveFontSizes({
                    xs: 40, sm: 70, md: 80, lg: 96,
                  }),
                }}
              >
                What is Secure Agent?
              </Typography>

            </Grid>

          </Grid>
        </Container>
      </Box>

      <Box sx={{
        height: 'auto',
        // minHeight: { md: '100vh' },
        '@media (min-width: 600px)': {
          paddingLeft: '24px',
          paddingRight: '24px',
        },
        backgroundColor: '#EEF1EF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 9 },
          pb: { xs: 3, md: 3, lg: 9 },
        }}
        >
          <Grid
            container
            columnSpacing={{
              xs: 0, sm: 0, md: 0, lg: 6,
            }}
            rowSpacing={{
              xs: 0, sm: 0, md: 0, lg: 3,
            }}
            sx={{ justifyContent: 'center' }}
          >
            <Grid item md={8}>
              <Grid
                container
                spacing={{
                  xs: 0, sm: 0, md: 0, lg: 3,
                }}
              >
                <Grid item md={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 2,
                      my: { xs: 0, md: 4, lg: 2 },
                    }}
                  >
                    <img src={SecureAgentImage} alt="QuantaSafe" style={{ height: '100px' }} />
                  </Box>
                </Grid>
                <Grid item md={10}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: '5px',
                      ...responsiveFontSizes({
                        xs: 22, sm: 28, md: 28, lg: 36,
                      }),
                      color: '#054A91',
                      fontWeight: 'bold',
                    }}
                  >
                    Summary Header
                  </Typography>
                  <Intro />
                </Grid>

                <Grid item md={23}>
                  <BodyParagraph1 />
                </Grid>
                <Grid item md={12}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: '5px',
                      ...responsiveFontSizes({
                        xs: 22, sm: 28, md: 28, lg: 36,
                      }),
                      color: '#054A91',
                      fontWeight: 'bold',
                    }}
                  >
                    Features
                  </Typography>
                  <Intro />
                </Grid>
              </Grid>

            </Grid>
            <Grid item md={4}>
              <Card sx={{ background: '#F7F7F7' }}>
                {/* <CardHeader title="Have more questions?" /> */}
                <Stack
                  spacing={1.5}
                  sx={{ p: 3 }}
                  component={m.div}
                  variants={{ hover: { opacity: 0.8 } }}
                >
                  <Typography variant="h3" sx={{ color: '#054A91', mb: 0.5 }}>
                    Have more questions?
                  </Typography>
                  <Stack spacing={0.5} sx={{ width: '100%' }}>
                    <TextIconLabel
                      icon={<Iconify icon={locationIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                      value={(
                        <>
                          Main Address:
                        </>
                      )}
                      sx={{ typography: 'subtitle2', mb: 0.5 }}
                    />
                    <Typography variant="body2">222 South 9th Street</Typography>
                    <Typography variant="body2">Suite 1600</Typography>
                    <Typography variant="body2">Minneapolis, MN 55402</Typography>
                  </Stack>

                  <Stack spacing={0.5}>
                    <TextIconLabel
                      icon={<Iconify icon={mobileIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                      value="Phone:"
                      sx={{ typography: 'subtitle2', mb: 0.5 }}
                    />
                    <Typography variant="body2">
                      <Link
                        sx={{ lineHeight: 0 }}
                        rel="noopener noreferrer"
                        href="tel:612-423-9015"
                      >
                        612-423-9015
                      </Link>
                    </Typography>
                  </Stack>

                  <Stack spacing={0.5}>
                    <TextIconLabel
                      icon={<Iconify icon={emailIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                      value="Email:"
                      sx={{ typography: 'subtitle2', mb: 0.5 }}
                    />
                    <Typography variant="body2">
                      <Link
                        sx={{ lineHeight: 0 }}
                        rel="noopener noreferrer"
                        href="mailto:sales@enquanta.io"
                      >
                        sales@enquanta.io
                      </Link>
                    </Typography>
                  </Stack>
                  <Button variant="contained" sx={{ width: '50%' }}>
                    Contact Us
                  </Button>
                </Stack>

              </Card>
            </Grid>

            <Grid item md={12}>
              <Grid
                container
                spacing={{
                  xs: 0, sm: 0, md: 0, lg: 3,
                }}
              >
                <Grid item md={12}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: '5px',
                      ...responsiveFontSizes({
                        xs: 22, sm: 28, md: 28, lg: 36,
                      }),
                      color: '#054A91',
                      fontWeight: 'bold',
                    }}
                  >
                    Use Cases
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Avatar
                    variant="rounded"
                    alt="plane"
                    src={planeImage}
                    sx={{ width: '100%', height: '200px' }}
                  />
                </Grid>
                <Grid item md={8}>
                  <Intro />

                </Grid>
                <Grid item md={8}>
                  <Intro />

                </Grid>
                <Grid item md={4}>
                  <Avatar
                    variant="rounded"
                    alt="plane"
                    src={planeImage}
                    sx={{ width: '100%', height: '200px' }}
                  />
                </Grid>

              </Grid>

            </Grid>
          </Grid>

        </Container>
      </Box>
    </>
  );
}
