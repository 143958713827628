/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  Link,
  Button,
  CardActions,
  Avatar,
} from '@mui/material';
import locationIcon from '@iconify/icons-carbon/location';
import mobileIcon from '@iconify/icons-carbon/mobile';
import emailIcon from '@iconify/icons-carbon/email';
import { m } from 'framer-motion';
import Iconify from '../Iconify';
import TextIconLabel from '../TextIconLabel';
import getWindowSize from '../../utils/getScreenSize';

import WhatIsQuantaSafeCoverImage from '../../assets/images/SecureVaultCover.png';
import { responsiveFontSizes } from '../../utils/getFontValue';
import planeImage from '../../assets/images/plane.png';

// TODO: Check if this is needed anymore3
import '../EnquantaAdvantage/EnquantaAdvantage.css';
import SecureVaultFeatures from './SecureVaultFeatures';
import SecureVaultUseCases from './SecureVaultUseCases';
import SecureVaultIntro from './SecureVaultIntro';

// TODO: clean up this doo doo

const regularTextFontSizes = {
  xs: 16, sm: 18, md: 18, lg: 18,
};

function Intro({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      QuantaSafe Secure Vault is a cutting-edge digital data storage solution for data at cold
      rest designed to safeguard data objects/assets such as firmware, datasets,
      and critical program information (CPI) with unparalleled quantum cyber resilience.
    </Typography>
  );
}

function BodyParagraph1({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      Utilizing the principles of true quantum entropy randomization and proprietary
      Quantum Hybrid Cipher (QHC) stacks, Secure Vault protects digital assets stored
      in non-volatile memory and only periodically updated or changed. This technology
      is particularly effective against data theft, tampering, and reverse engineering, storing sensitive digital assets in an encrypted state that is virtually impregnable. For each set of digital assets, QuantaSafe Secure Vault creates a unique Quantum Cryptex Unit (QCU)(™) and a corresponding Quantum Incrypted Data (QuID)(™) protected package. This ensures the integrity and security of protected data even in the event of system compromise.

    </Typography>

  );
}

function Features({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      Data protection in cold storage
      Physical separation between QuID (lock) and QCU (key)
      Secure transmission of the QCU is performed over encrypted TCP to unlock the QuID
      Versatile software-only design allows Secure Vault to operate within various network topologies
      Could be used in conjunction with QuantaSafe Secure Storage as the PSS
      Quantum Resilience
      Randomized QHC stack and processes unique to each packager execution
      Volatile memory working area for unpackaged sensitive information; cleared when power is removed
      QuantaSafe binaries that control the QHC dicryption process are stored in a physically separate location from the QuID and transferred to the secondary node at unpackaging time. This sensitive code is stored in volatile memory and immediately discarded after unpackaging.
      Anti-Tamper
      The QCU is tightly coupled to the associated unpackager binary via a unique fingerprint, resulting in unpackaging failure if an invalid QCU fingerprint is detected in the QHCb
      Attempts to modify or tamper with the QuID, QCU, or QuantaSafe binaries will be detected and cause subsequent unpackaging attempts to fail
      Option to have QuID and QCU “shuffled” after each execution so stale copies of QuID and/or QCU are invalidated, making replay attacks infeasible

    </Typography>

  );
}

function UseCases1({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      Standalone Primary: Any data can be protected with Vault if separation is maintained between QuID and QCU, and the QCU is kept secure. The simplest use case is a standalone primary node that has a QuID in persistent memory, and a QCU on a secure external device acting as Primary System Storage (PSS). The secure device is plugged into the primary node, the QuID data is unpackaged into volatile memory, then the system continues to boot and/or use the sensitive data as needed.
    </Typography>

  );
}

function UseCases2({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      Primary-Secondary: A distribution package (containing a QuID) is deployed to persistent memory on a secondary node. Additional distribution packages can be deployed to as many secondary nodes as needed. The QCU(s) are deployed to PSS (ideally some form of removable media that can be stored securely when not in use). The primary node acts as the “head of the snake”; without the primary node, none of the secondary nodes can unpackage their QuIDs. As secondary nodes boot, each retrieves its QCU from PSS via secure TCP, each unpackages the QuID into volatile memory, then each continues to boot and/or use the sensitive data as needed.
    </Typography>

  );
}

export default function SecureVault() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  let quantumIntelligenceDiagramHeight = '250px';
  if (windowSize.innerWidth >= 1200) {
    quantumIntelligenceDiagramHeight = '370px';
  } else if (windowSize.innerWidth >= 900) {
    quantumIntelligenceDiagramHeight = '320px';
  } else if (windowSize.innerWidth >= 600) {
    quantumIntelligenceDiagramHeight = '300px';
  }
  return (
    <Box sx={{
      height: 'auto',
      // minHeight: { md: '100vh' },
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      backgroundColor: '#EEF1EF',
      display: 'flex',
      marginTop: '80px',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Grid
        container
        columnSpacing={{
          xs: 0, sm: 0, md: 0, lg: 6,
        }}
        rowSpacing={{
          xs: 2, sm: 0, md: 0, lg: 3,
        }}
        sx={{ justifyContent: 'center' }}
      >
        <Grid
          item
          md={12}
          sx={{
            backgroundColor: '#DEDBE9',
            pl: 0,
          }}
          style={{ paddingLeft: '0px' }}
        >
          <SecureVaultIntro />
          <Box
            component="svg"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: '-10px',
              mt: 8,
            }}
          >
            <path
              fill="#EEF1EF"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            />
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          style={{ paddingLeft: '0px' }}
        >
          <Typography
            variant="h3"
            sx={{
              marginBottom: '5px',
              ...responsiveFontSizes({
                xs: 22, sm: 28, md: 28, lg: 36,
              }),
              color: '#054A91',
              fontWeight: 'bold',
              textAlign: 'center',
              pl: { lg: 40 },
              pr: { lg: 40 },
              mb: 2,
              mt: 0,
            }}
          >
            What Is Secure Vault?
          </Typography>
          <Container>
            <Typography
              sx={{
                marginBottom: '5px',
                // ...responsiveFontSizes({
                //   xs: 22, sm: 28, md: 28, lg: 36,
                // }),
              }}
            >
              QuantaSafe Secure Vault is an advanced software storage solution with quantum resilience protection for data-at-rest. It ensures the protection of digital assets, including firmware, datasets, and critical program information (CPI) by leveraging the power of Quantum Random Number Generators (QRNG) and proprietary Quantum Hybrid Cipher (QHC) stacks. Secure Vault secures digital assets by incrypting a unique protected pair of packages containing an amalgamated codex, called a Quantum Cryptex Unit (QCU), and its corresponding Quantum Incrypted Data (QuID) bundle. Our terminology “incrypt” and “dicrypt” is analogous to “encrypt” and “decrypt”, but encompasses our expanded cryptographic agile process. This technology is particularly effective against data theft, tampering, and reverse engineering, by storing sensitive digital assets in an impenetrable incrypted state. This guarantees the security and integrity of the protected data even if the system is compromised.
            </Typography>
          </Container>
          <Box
            component="svg"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: '-10px',
              mt: 8,
            }}
          >
            <path
              fill="#B1DAEC"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            />
          </Box>

        </Grid>
        <Grid
          item
          md={12}
          style={{ paddingLeft: '0px' }}
          sx={{
            backgroundColor: '#B1DAEC',
          }}
        >
          <Container>
            <Typography
              variant="h3"
              sx={{
                marginBottom: '5px',
                ...responsiveFontSizes({
                  xs: 22, sm: 28, md: 28, lg: 36,
                }),
                color: '#054A91',
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 4,
              }}
            >
              Features
            </Typography>
            <SecureVaultFeatures />
          </Container>

          <Box
            component="svg"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: '-10px',
              mt: 12,
            }}
          >
            <path
              fill="#EEF1EF"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            />
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          style={{ paddingLeft: '320px' }}
          sx={{
            backgroundColor: '#EEF1EF',
            pl: { lg: 40 },
            pr: { lg: 40 },
            pb: 5,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              marginBottom: '5px',
              ...responsiveFontSizes({
                xs: 22, sm: 28, md: 28, lg: 36,
              }),
              color: '#054A91',
              fontWeight: 'bold',
              textAlign: 'center',
              mb: 4,
            }}
          >
            Use Cases
          </Typography>
          <SecureVaultUseCases />
        </Grid>
      </Grid>
    </Box>
  );
}
