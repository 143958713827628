/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
// @mui
import { Box } from '@mui/material';
//
import Popup from './Popup';
import Marker from './Marker';
import MapStyle from './MapStyle';

// ----------------------------------------------------------------------
const defaultProps = {
  center: {
    lat: 44.9737662,
    lng: -93.2721392,
  },
  zoom: 14,
};

export default function ContactMap({ sx, ...other }) {
  const [tooltip, setTooltip] = useState(false);

  return (
    <Box sx={{ height: { xs: 344, md: 484 }, overflow: 'hidden', ...sx }} {...other}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBE956NS96FB_p3sL2tltVfWrVHJ-PZDHg' }}
        // center={centerMap}
        // zoom={2}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          styles: MapStyle,
          disableDefaultUI: true,
        }}
      >
        <Marker
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          onOpen={() => {
            console.log('open!');
            setTooltip(true);
          }}
        />

        {tooltip && (
          <Popup
            lat={44.9737599}
            lng={defaultProps.center.lng}
            onClose={() => setTooltip(false)}
          />
        )}
      </GoogleMapReact>
    </Box>
  );
}

ContactMap.propTypes = {
  // offices: PropTypes.array.isRequired,
  sx: PropTypes.object,
};
