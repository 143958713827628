/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Typography,
  Stack,
  Button,
  Card,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import locationIcon from '@iconify/icons-carbon/location';
import mobileIcon from '@iconify/icons-carbon/mobile';
import emailIcon from '@iconify/icons-carbon/email';
import { m } from 'framer-motion';
import Iconify from '../Iconify';
import TextIconLabel from '../TextIconLabel';

export default function StartOurCoPilotBox() {
  const navigate = useNavigate();

  return (
    <Card sx={{ background: '#F7F7F7' }}>
      {/* <CardHeader title="Have more questions?" /> */}
      <Stack
        spacing={1.5}
        sx={{ p: 3 }}
        component={m.div}
        variants={{ hover: { opacity: 0.8 } }}
      >
        <Typography variant="h3" sx={{ color: '#054A91', mb: 0.5 }}>
          Have more questions?
        </Typography>
        <Stack spacing={0.5} sx={{ width: '100%' }}>
          <TextIconLabel
            icon={<Iconify icon={locationIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
            value={(
              <>
                Main Address:
              </>
            )}
            sx={{ typography: 'subtitle2', mb: 0.5 }}
          />
          <Typography variant="body2">222 South 9th Street</Typography>
          <Typography variant="body2">Suite 1600</Typography>
          <Typography variant="body2">Minneapolis, MN 55402</Typography>
        </Stack>

        <Stack spacing={0.5}>
          <TextIconLabel
            icon={<Iconify icon={mobileIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
            value="Phone:"
            sx={{ typography: 'subtitle2', mb: 0.5 }}
          />
          <Typography variant="body2">
            <Link
              sx={{ lineHeight: 0 }}
              rel="noopener noreferrer"
              href="tel:612-423-9015"
            >
              612-423-9015
            </Link>
          </Typography>
        </Stack>

        <Stack spacing={0.5}>
          <TextIconLabel
            icon={<Iconify icon={emailIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
            value="Email:"
            sx={{ typography: 'subtitle2', mb: 0.5 }}
          />
          <Typography variant="body2">
            <Link
              sx={{ lineHeight: 0 }}
              rel="noopener noreferrer"
              href="mailto:sales@enquanta.io"
            >
              sales@enquanta.io
            </Link>
          </Typography>
        </Stack>
        <Button
          variant="contained"
          sx={{ width: '50%' }}
          onClick={() => {
            navigate('/contactUs');
          }}
        >
          Contact Us
        </Button>
      </Stack>

    </Card>
  );
}
