import React from 'react';
import {
  Box, Container, Stack, Typography,
} from '@mui/material';
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from '@mui/lab';
import useResponsive from '../../hooks/useResponsive';
import { responsiveFontSizes } from '../../utils/getFontValue';

export const historyEntries = [
  {
    timelineColor: '#009DDC',
    year: '2022 - Present Day',
    title: 'EnQuanta',
    description:
      `Vetting of QuantaSafe started multi-yar contract with DOD Program
        KBR contractor. Launched Enquanta Website`,
  },
  {
    timelineColor: '#009DDC',
    year: '2021',
    title: 'QuantaSafe',
    description:
      `Defined QuantaSafe development roadmap. Predecessor LLC rolled into
      Delaware C Corp`,
  },
  {
    timelineColor: '#009DDC',
    year: '2020',
    title: 'Acquiring resources',
    description:
      `Foundation technology assets acquired.
      Stealth development continues.`,
  },
  {
    timelineColor: '#009DDC',
    year: '2019',
    title: 'Stealthy Beginnings',
    description:
      `Quantum Cyber Resilience opportunity
      identified. Stealth development started.`,
  },
];

export default function About() {
  const isDesktop = useResponsive('up', 'md');
  // const isLarge = useResponsive('up', 'lg');

  return (
    <Box sx={{
      height: 'auto',
      minHeight: { md: '100vh' },
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      marginTop: '64px',
      backgroundColor: '#EEF1EF',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Container sx={{
        py: { xs: 3, md: 3, lg: 9 },
      }}
      >
        <Stack
          spacing={3}
          sx={{
            maxWidth: { xs: 480, lg: 580 },
            textAlign: 'center',
            mx: 'auto',
            mb: { xs: 4, md: 8 },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              ...responsiveFontSizes({
                xs: 28, sm: 30, md: 36, lg: 48,
              }),
            }}
          >
            Our Story

          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            EnQuanta&apos;s patent-pending Quantum Hybrid Ciphers (QHC)
            provide security for various digital asset categories, such as firmware, IP,
            and Critical Program Information (CPI). This ensures that your operations can
            continue even in the event of a data breach or theft.
          </Typography>
        </Stack>
        <Timeline position={isDesktop ? 'alternate' : 'right'}>
          {historyEntries.map((value, index) => {
            const {
              title, description, year, timelineColor,
            } = value;
            return (
              <TimelineItem
                key={title}
                sx={{
                  '&:before': {
                    display: { xs: 'none', md: 'block' },
                  },
                }}
              >
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: timelineColor }} />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pt: 0, pb: { xs: 3, md: 5 } }}>
                  <Typography variant="subtitle3" sx={{ color: timelineColor }}>
                    {year}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: 0.5,
                      mb: 1,
                      ...responsiveFontSizes({
                        xs: 18, sm: 20, md: 25, lg: 32,
                      }),
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      maxWidth: { md: 360 },
                      ...(index % 2 && {
                        ml: 'auto',
                      }),
                    }}
                  >
                    {description}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Container>
    </Box>
  );
}
