import React from 'react';
import { Box } from '@mui/material';
import ProblemsEnquanta from './ProblemsEnQuanta/ProblemsEnquanta';
import EnquantaAdvantage from './EnquantaAdvantage/EnquantaAdvantage';
import PostLandingText from './PostLandingText/PostLandingText';
import Landing from './Landing/Landing';

export default function Home() {
  return (
    <Box sx={{ paddingTop: '64px' }}>
      <Landing />
      <PostLandingText />
      <ProblemsEnquanta />
      <EnquantaAdvantage />
    </Box>
  );
}
