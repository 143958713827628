/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
} from '@mui/material';
import { pxToRem, responsiveFontSizes } from '../../utils/getFontValue';
import './EnquantaAdvantage.css';
import getWindowSize from '../../utils/getScreenSize';
import AllProductsImage from '../../assets/images/AllProducts.png';

const regularTextFontSizes = {
  xs: 16, sm: 18, md: 18, lg: 18,
};

export const pageHeader = 'The EnQuanta Advantage';

function CyberSecurity1({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      Deploy our advanced solutions to leverage the benefits of enhanced quantum
      cyber resilience within your systems. This comprehensive approach is designed
      to fortify your digital infrastructure, offering robust protection at every
      critical phase - preemptively safeguarding against potential threats before
      they occur, actively defending during a cyber breach, and providing
      resilient recovery strategies after an incident.
    </Typography>
  );
}

function CyberSecurity2({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      Our solutions are meticulously engineered to integrate seamlessly with
      your existing security protocols, enhancing your defensive capabilities
      with cutting-edge quantum technology. This ensures a fortified defense
      mechanism that is equipped to handle the evolving landscape of cyber threats,
      thereby maintaining the integrity and confidentiality of your
      sensitive data across all operational levels.
    </Typography>
  );
}

export default function EnquantaAdvantage() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  let allProductsImageHeight = '300px';
  if (windowSize.innerWidth >= 1200) {
    allProductsImageHeight = '360px';
  } else if (windowSize.innerWidth >= 900) {
    allProductsImageHeight = '350px';
  } else if (windowSize.innerWidth >= 600) {
    allProductsImageHeight = '350px';
  }

  return (
    <Box sx={{
      height: 'auto',
      backgroundColor: '#EEF1EF',
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    }}
    >
      <Container sx={{
        paddingTop: 6,
        paddingBottom: 4,
      }}
      >
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            mx: 0,
            textAlign: 'center',
            color: '#422A76',
            fontSize: pxToRem(34),
            fontFamily: 'branding',
            fontWeight: 'bold',
            ...responsiveFontSizes({
              xs: 32, sm: 36, md: 40, lg: 48,
            }),
            lineHeight: { xs: 1.2, md: 1.75 },
          }}
        >
          {pageHeader}
        </Typography>
        <Box sx={{
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        >
          <Container sx={{
            pt: { xs: 3, md: 3, lg: 9 },
            pb: { xs: 3, md: 3, lg: 9 },
            pl: { xs: 0, lg: 0 },
            pr: { xs: 0, lg: 0 },
          }}
          >
            <Grid
              container
              spacing={{
                xs: 0, sm: 0, md: 0, lg: 0,
              }}
              my={{
                xs: 3, sm: 3, md: 3, lg: 0,
              }}
              rowGap={{
                xs: 10, sm: 10, md: 7, lg: 5,
              }}
              // rowSpacing={{ lg: 10 }}
              sx={{ justifyContent: 'center' }}
            >
              {windowSize.innerWidth >= 900
                && (
                  <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        py: 1,
                        display: 'flex',
                      }}
                    >
                      <img
                        src={AllProductsImage}
                        alt="QuantaSafe"
                        style={{ height: allProductsImageHeight }}
                      />
                    </Box>
                  </Grid>
                )}
              <Grid item md={6}>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: '5px',
                    ...responsiveFontSizes({
                      xs: 22, sm: 28, md: 28, lg: 32,
                    }),
                    fontWeight: 'bold',
                    color: '#422A76',
                  }}
                >
                  Quanta
                  <span style={{ color: '#7F5CAD' }}>Safe</span>
                </Typography>
                <CyberSecurity1 />
                {windowSize.innerWidth < 900 && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 2,
                      my: { xs: 0, md: 4, lg: 2 },
                    }}
                  >
                    <img
                      src={AllProductsImage}
                      alt="QuantaSafe"
                      style={{ height: allProductsImageHeight }}
                    />
                  </Box>
                )}
                <CyberSecurity2 />
              </Grid>
            </Grid>
          </Container>
        </Box>

      </Container>
    </Box>
  );
}
