/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Container, Typography,
  Grid, Box, Fade, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import WAVES from 'vanta/dist/vanta.waves.min';
import { get, isString } from 'lodash';
import LandingCoverImage from '../../assets/images/CoverImages/LandingCoverImage.png';

import { responsiveFontSizes } from '../../utils/getFontValue';

export const fadingVoiceItText = 'A VoiceIt Technologies Company';
export const bottomText = 'Neutralize Every Threat';
export const enquantaText = 'EnQuanta';

const isRedirect = (source) => {
  if (isString(source)) {
    return source.includes('voiceit');
  }
  return false;
};

export default function Landing() {
  const [showVoiceIt, setShowVoiceIt] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowVoiceIt(false);
    }, 5000);
  });
  const { source } = useParams();
  const [vantaEffect, setVantaEffect] = useState(null);
  const [showDialog, setShowDialog] = useState(isRedirect(source));
  // const [showDialog, setShowDialog] = useState(true);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(WAVES({
        el: myRef.current,
        color: 0x032c57,
        shininess: 75.00,
        waveHeight: 16.00,
        waveSpeed: 1.00,
        zoom: 0.93,
      }));
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);
  return (
    <Box
      ref={myRef}
      sx={{
        backgroundImage: `url(${LandingCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
      }}
    >
      <Container sx={{
        width: '100vw',
        height: {
          xs: '300px', sm: '500px', md: '700px', lg: '100vh',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#EEF1EF',
      }}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              sx={{
                color: '#EEF1EF',
                fontFamily: 'branding',
                fontWeight: 'bold',
                ...responsiveFontSizes({
                  xs: 68, sm: 95, md: 115, lg: 128,
                }),
              }}
            >
              {enquantaText}
            </Typography>
            <Fade
              in={showVoiceIt}
              appear
              timeout={4000}
            >
              <Typography
                variant="h4"
                sx={{
                  color: '#85CAEB',
                  mt: -1,
                  ...responsiveFontSizes({
                    xs: 12, sm: 16, md: 22, lg: 24,
                  }),
                }}
              >
                {fadingVoiceItText}
              </Typography>
            </Fade>
            <Typography
              variant="h4"
              sx={{
                color: '#EEF1EF',
                margin: '2vh 0',
                ...responsiveFontSizes({
                  xs: 22, sm: 29, md: 42, lg: 48,
                }),
              }}
            >
              {bottomText}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: '#EEF1EF',
                margin: '2vh 0',
                ...responsiveFontSizes({
                  xs: 22, sm: 29, md: 42, lg: 30,
                }),
              }}
            >
              AI with Quantum computers will
              break current encryption
              <br />
              You have to start now.
            </Typography>
          </Grid>

        </Grid>
      </Container>
      <Dialog
        open={showDialog}
        onClose={() => { setShowDialog(false); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: '#054A91',
          }}
        >
          <Container sx={{
            width: '100%',
            height: {
              xs: '200px', sm: '200px', md: '200px', lg: '200px',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#EEF1EF',
          }}
          >
            <Grid container columnSpacing={3}>
              <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                <Typography
                  variant="h1"
                  sx={{
                    color: '#EEF1EF',
                    fontFamily: 'branding',
                    fontWeight: 'bold',
                    ...responsiveFontSizes({
                      xs: 28, sm: 42,
                    }),
                  }}
                >
                  VoiceIt is now EnQuanta
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: '#EEF1EF',
                    margin: '2vh 0',
                    ...responsiveFontSizes({
                      xs: 14, sm: 16,
                    }),
                  }}
                >
                  Threats are evolving, so VoiceIt became EnQuanta to stop them.
                </Typography>
              </Grid>

            </Grid>
          </Container>
        </Box>
        <Container sx={{
          alignItems: 'center',
          // px: 60,
          backgroundColor: '#EEF1EF',
          py: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <Typography sx={{
            ...responsiveFontSizes({
              xs: 12, sm: 14,
            }),
          }}
          >
            VoiceIt has been a leader in the Biometric Security space since 1998 releasing
            the first Voice Authentication (GINA) for the Windows Operating Systems called
            VoiceSecureIt, and in 2007 released the first Biometric-as-a-Service (BaaS)
            RESTful APIs for voice biometrics worldwide. Cyber threats continued to evolve
            over the years and with quantum computing and Y2Q looming we decided to be the
            first to offer true protection of data and infrastructure from pre and post
            quantum threats. EnQuanta was born.
          </Typography>
          <Typography sx={{
            mt: 2,
            ...responsiveFontSizes({
              xs: 12, sm: 14,
            }),
          }}

          >
            EnQuanta&apos;s patent pending Quantum Hybrid Ciphers protection safeguards
            all your digital asset
            types (e.g. firmware, IP software, CPI) from a cyber breach so you can keep
            running and not worry about theft, even if your data assets are stolen or locked.
            Additionally, EnQuanta&apos;s patented biometric access protection tools mitigate
            cyber access threats.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#F17300',
              ...responsiveFontSizes({
                xs: 12, sm: 14,
              }),
              mt: 3,
            }}
            onClick={() => { setShowDialog(false); }}
          >
            Dismiss
          </Button>
        </Container>
      </Dialog>
    </Box>
  );
}
